/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsCart3, BsCartX } from "react-icons/bs";
import { MdOutlineLocalShipping } from "react-icons/md";
import { AiOutlinePause } from "react-icons/ai";
import { TbTruckReturn } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";
import Button from "./Button";
import moment from "moment";

import Loader from "./Loader";
import GridTile from "./GridTile";
import { getDashboardReports, getOrderAgingReport } from "../redux/authSlice";

import OutsideClickHandler from "react-outside-click-handler";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Table from "./Table";

const FILTER_BUTTONS = [
  "Last Month",
  "Last 30 Days",
  "Last 90 Days",
  "Year To Date",
  "All Time",
];

function DasboardMain() {
  const [dashboardDetails, setDashboardDetails] = useState<any>("");
  const [orderAgingTableList, setOrderAgingTableList] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardFromDate, setDashboardFromDate] = useState<any>(
    localStorage.getItem("dashboardFromDate") ?? ""
  );
  const [dashboardThruDate, setDashbordThruDate] = useState<any>(
    localStorage.getItem("dashboardThruDate") ?? ""
  );
  const [activeFilter, setActiveFilter] = useState(
    localStorage.getItem("activeFilter") || ""
  );
  const [isDateRangePickerVisible, setDateRangePickerVisible] = useState(false);
  const [selectedDateRange, setSelectedDateRange] =
    useState<any>("Select Time Range");
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(false);
  const [date, setDate] = useState<any>(
    !activeFilter
      ? [
          {
            startDate: dashboardFromDate
              ? new Date(Date.parse(dashboardFromDate))
              : new Date(),
            endDate: dashboardThruDate
              ? new Date(Date.parse(dashboardThruDate))
              : new Date(),
            key: "selection",
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]
  );
  let todayDate: any = new Date();
  let dd = String(todayDate.getDate()).padStart(2, "0");
  let mm = String(todayDate.getMonth() + 1).padStart(2, "0");
  let yyyy = todayDate.getFullYear();
  todayDate = yyyy + "-" + mm + "-" + dd;
  const startingDate = yyyy + "-" + mm + "-01";
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let dashboardFromDate;
      let dashboardThruDate;
      if (
        localStorage.getItem("dashboardFromDate") &&
        localStorage.getItem("dashboardThruDate")
      ) {
        const fromDate: any = localStorage
          .getItem("dashboardFromDate")
          ?.split("T");
        const thruDate: any = localStorage
          .getItem("dashboardThruDate")
          ?.split("T");
        dashboardFromDate = fromDate[0];
        dashboardThruDate = thruDate[0];
        if (!activeFilter) {
          setSelectedDateRange(`${dashboardFromDate} - ${dashboardThruDate}`);
        }
      } else {
        const currDate = new Date();
        dashboardFromDate = `${currDate.getFullYear()}-${
          currDate.getMonth() + 1 < 10
            ? `0${currDate.getMonth() + 1}`
            : currDate.getMonth() + 1
        }-01`;
        dashboardThruDate = `${currDate.getFullYear()}-${
          currDate.getMonth() + 1 < 10
            ? `0${currDate.getMonth() + 1}`
            : currDate.getMonth() + 1
        }-${
          currDate.getDate() < 10
            ? `0${currDate.getDate()}`
            : currDate.getDate()
        }`;
        setSelectedDateRange(`${dashboardFromDate} - ${dashboardThruDate}`);
      }
      await dispatch(
        getDashboardReports({
          setDashboardDetails,
          monthStartDate: dashboardFromDate,
          monthEndDate: dashboardThruDate,
        })
      );
      setIsLoading(false);
      setIsFirstTimeLoading(true);
      await dispatch(getOrderAgingReport(setOrderAgingTableList));
      setIsFirstTimeLoading(false);
    };
    fetchData();
  }, [dispatch, history]); //eslint-disable-line

  async function handleDateRangeButtonClick(DateRange: any) {
    const currentDate = moment();
    const currentDateFormatted = currentDate.format("YYYY-MM-DD");
    let dashboardFromDate;
    let dashboardThruDate;

    switch (DateRange) {
      case "Last 30 Days":
        dashboardFromDate = currentDate
          .clone()
          .subtract(29, "days")
          .format("YYYY-MM-DD");
        dashboardThruDate = currentDateFormatted;
        break;
      case "Last Month":
        dashboardFromDate = currentDate
          .clone()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        dashboardThruDate = currentDate
          .clone()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "Last 90 Days":
        dashboardFromDate = currentDate
          .clone()
          .subtract(89, "days")
          .format("YYYY-MM-DD");
        dashboardThruDate = currentDateFormatted;
        break;
      case "Year To Date":
        dashboardFromDate = currentDate
          .clone()
          .startOf("year")
          .format("YYYY-MM-DD");
        dashboardThruDate = currentDateFormatted;
        break;
      default:
        dashboardFromDate = moment(0).format("YYYY-MM-DD");
        dashboardThruDate = currentDateFormatted;
        break;
    }

    setActiveFilter(DateRange);
    setSelectedDateRange("Select Time Range");
    localStorage.setItem("dashboardFromDate", dashboardFromDate);
    localStorage.setItem("dashboardThruDate", dashboardThruDate);
    setDashboardFromDate(dashboardFromDate);
    setDashbordThruDate(dashboardThruDate);
    localStorage.setItem("activeFilter", DateRange);

    setDate([{ startDate: new Date(), endDate: new Date(), key: "selection" }]);
    setIsLoading(true);

    await dispatch(
      getDashboardReports({
        setDashboardDetails,
        monthStartDate: dashboardFromDate,
        monthEndDate: dashboardThruDate,
      })
    );

    setIsLoading(false);
  }

  const columnsOrderAgingTable = [
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => {
        if (
          (status.toLowerCase() === "placed" &&
            !orderAgingTableList.orderPlacedList) ||
          (orderAgingTableList.orderPlacedList &&
            orderAgingTableList.orderPlacedList.length === 0) ||
          (status.toLowerCase() === "approved" &&
            !orderAgingTableList.orderApprovedList) ||
          (orderAgingTableList.orderApprovedList &&
            orderAgingTableList.orderApprovedList.length === 0) ||
          (status.toLowerCase() === "held" &&
            !orderAgingTableList.orderHeldList) ||
          (orderAgingTableList.orderHeldList &&
            orderAgingTableList.orderHeldList.length === 0) ||
          (status.toLowerCase() === "preorder" &&
            !orderAgingTableList.preordersList) ||
          (orderAgingTableList.preordersList &&
            orderAgingTableList.preordersList.length === 0) ||
          (status.toLowerCase() === "exchange" &&
            !orderAgingTableList.exchangeOrdersList) ||
          (orderAgingTableList.exchangeOrdersList &&
            orderAgingTableList.exchangeOrdersList.length === 0)
        ) {
          return;
        }
        return (
          <h1
            className={`${
              status.toLowerCase() === "placed"
                ? (status.toLowerCase() === "placed" && placedCount_7 > 0) ||
                  (orderAgingTableList.orderPlacedList && placedCount_6 > 0) ||
                  (orderAgingTableList.orderPlacedList && placedCount_5 > 0) ||
                  (orderAgingTableList.orderPlacedList && placedCount_4 > 0) ||
                  (orderAgingTableList.orderPlacedList && placedCount_3 > 0)
                  ? "bg-primary-red-dark"
                  : orderAgingTableList.orderPlacedList && placedCount_2 > 0
                  ? "bg-yellow-500"
                  : "bg-green-500"
                : status.toLowerCase() === "approved"
                ? (status.toLowerCase() === "approved" &&
                    orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count7 > 0) ||
                  (orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count6 > 0) ||
                  (orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count5 > 0) ||
                  (orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count4 > 0) ||
                  (orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count3 > 0)
                  ? "bg-primary-red-dark"
                  : orderAgingTableList.orderApprovedList &&
                    orderAgingTableList.orderApprovedList.length > 0 &&
                    orderAgingTableList.orderApprovedList[0].count2 > 0
                  ? "bg-yellow-500"
                  : "bg-green-500"
                : status.toLowerCase() === "held"
                ? (status.toLowerCase() === "held" &&
                    orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count7 > 0) ||
                  (orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count6 > 0) ||
                  (orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count5 > 0) ||
                  (orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count4 > 0) ||
                  (orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count3 > 0)
                  ? "bg-primary-red-dark"
                  : orderAgingTableList.orderHeldList &&
                    orderAgingTableList.orderHeldList.length > 0 &&
                    orderAgingTableList.orderHeldList[0].count2 > 0
                  ? "bg-yellow-500"
                  : "bg-green-500"
                : status.toLowerCase() === "preorder"
                ? (status.toLowerCase() === "preorder" &&
                    orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count7 > 0) ||
                  (orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count6 > 0) ||
                  (orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count5 > 0) ||
                  (orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count4 > 0) ||
                  (orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count3 > 0)
                  ? "bg-primary-red-dark"
                  : orderAgingTableList.preordersList &&
                    orderAgingTableList.preordersList.length > 0 &&
                    orderAgingTableList.preordersList[0].count2 > 0
                  ? "bg-yellow-500"
                  : "bg-green-500"
                : status.toLowerCase() === "exchange"
                ? (status.toLowerCase() === "exchange" &&
                    orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count7 > 0) ||
                  (orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count6 > 0) ||
                  (orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count5 > 0) ||
                  (orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count4 > 0) ||
                  (orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count3)
                  ? "bg-primary-red-dark"
                  : orderAgingTableList.exchangeOrdersList &&
                    orderAgingTableList.exchangeOrdersList.length > 0 &&
                    orderAgingTableList.exchangeOrdersList[0].count2 > 0
                  ? "bg-yellow-500"
                  : "bg-green-500"
                : console.log(null)
            }  px-1.5 py-0.5 text-xs rounded-sm font-bold text-white text-center`}
            style={{ width: "95px", letterSpacing: "0.03em" }}
          >
            {status.toUpperCase()}
          </h1>
        );
      },
    },
    {
      title: "<1 day",
      dataIndex: "lessThanOneDays",
    },
    {
      title: "1-7 days",
      dataIndex: "oneToSevenDays",
    },
    {
      title: "8-14 days",
      dataIndex: "eightToFourteenDays",
    },
    {
      title: "15-30 days",
      dataIndex: "fifteenToThirtyDays",
    },
    {
      title: "31-60 days",
      dataIndex: "thirtyOneToSixtyDays",
    },
    {
      title: "61-90 days",
      dataIndex: "sixtyOneToNinetyDays",
    },
    {
      title: "91+ days",
      dataIndex: "ninetyOnePlusDays",
    },
  ];

  const placedCount_1 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count1;
  const placedCount_2 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count2;
  const placedCount_3 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count3;
  const placedCount_4 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count4;
  const placedCount_5 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count5;
  const placedCount_6 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count6;
  const placedCount_7 =
    orderAgingTableList.orderPlacedList &&
    orderAgingTableList.orderPlacedList.length > 0 &&
    orderAgingTableList.orderPlacedList[0].count7;

  const dateConvert = (nb: any) => {
    var date: any = new Date(nb);
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  const todayDateData = todayDate;

  const placedOrderCountFrom_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate
  );
  const placedOrderCountThru_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].thruDate
  );
  const placedOrderCountFrom_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate1
  );
  const placedOrderCountThru_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].thruDate1
  );
  const placedOrderCountFrom_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate2
  );
  const placedOrderCountThru_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].thruDate2
  );

  const placedOrderCountFrom_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate3
  );
  const placedOrderCountThru_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList[0].thruDate3
  );
  const placedOrderCountFrom_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate4
  );
  const placedOrderCountThru_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].thruDate4
  );
  const placedOrderCountThru_7 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderPlacedList &&
      orderAgingTableList.orderPlacedList.length > 0 &&
      orderAgingTableList.orderPlacedList[0].fromDate5
  );

  ////Approved
  const approveOrderCountFrom_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate
  );
  const approveOrderCountThru_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].thruDate
  );
  const approveOrderCountFrom_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate1
  );
  const approveOrderCountThru_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].thruDate1
  );
  const approveOrderCountFrom_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate2
  );
  const approveOrderCountThru_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].thruDate2
  );

  const approveOrderCountFrom_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate3
  );
  const approveOrderCountThru_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].thruDate3
  );
  const approveOrderCountFrom_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate4
  );
  const approveOrderCountThru_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].thruDate4
  );
  const approveOrderCountThru_7 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderApprovedList &&
      orderAgingTableList.orderApprovedList.length > 0 &&
      orderAgingTableList.orderApprovedList[0].fromDate5
  );

  //held
  const heldOrderCountFrom_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate
  );
  const heldOrderCountThru_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].thruDate
  );
  const heldOrderCountFrom_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate1
  );
  const heldOrderCountThru_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].thruDate1
  );
  const heldOrderCountFrom_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate2
  );
  const heldOrderCountThru_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].thruDate2
  );

  const heldOrderCountFrom_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate3
  );
  const heldOrderCountThru_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].thruDate3
  );
  const heldOrderCountFrom_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate4
  );
  const heldOrderCountThru_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].thruDate4
  );
  const heldOrderCountThru_7 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.orderHeldList &&
      orderAgingTableList.orderHeldList.length > 0 &&
      orderAgingTableList.orderHeldList[0].fromDate5
  );

  //preOrder
  const preOrderCountFrom_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate
  );
  const preOrderCountThru_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].thruDate
  );
  const preOrderCountFrom_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate1
  );
  const preOrderCountThru_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].thruDate1
  );
  const preOrderCountFrom_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate2
  );
  const preOrderCountThru_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].thruDate2
  );

  const preOrderCountFrom_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate3
  );
  const preOrderCountThru_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].thruDate3
  );
  const preOrderCountFrom_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate4
  );
  const preOrderCountThru_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].thruDate4
  );
  const preOrderCountThru_7 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.preordersList &&
      orderAgingTableList.preordersList.length > 0 &&
      orderAgingTableList.preordersList[0].fromDate5
  );
  //ExchangeOrde List
  const exchangeOrderCountFrom_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate
  );
  const exchangeOrderCountThru_2 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].thruDate
  );
  const exchangeOrderCountFrom_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate1
  );
  const exchangeOrderCountThru_3 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].thruDate1
  );
  const exchangeOrderCountFrom_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate2
  );
  const exchangeOrderCountThru_4 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].thruDate2
  );

  const exchangeOrderCountFrom_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate3
  );
  const exchangeOrderCountThru_5 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].thruDate3
  );
  const exchangeOrderCountFrom_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate4
  );
  const exchangeOrderCountThru_6 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].thruDate4
  );
  const exchangeOrderCountThru_7 = dateConvert(
    orderAgingTableList &&
      orderAgingTableList.exchangeOrdersList &&
      orderAgingTableList.exchangeOrdersList.length > 0 &&
      orderAgingTableList.exchangeOrdersList[0].fromDate5
  );

  const dataSourceOrderAgingTable = [
    {
      status: "placed",

      lessThanOneDays:
        placedCount_1 === 0 ? (
          "0"
        ) : (
          <h1 className="text-link-text hover:text-link-text underline">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${todayDateData}&thruDate=${todayDateData}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_1}
              </h1>
            </Link>
          </h1>
        ),
      oneToSevenDays:
        placedCount_2 === 0 ? (
          <h1 className="">0</h1>
        ) : (
          <h1>
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${placedOrderCountFrom_2}&thruDate=${placedOrderCountThru_2}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_2}
              </h1>
            </Link>
          </h1>
        ),
      eightToFourteenDays:
        placedCount_3 === 0 ? (
          <h1>0</h1>
        ) : (
          <h1 className="text-link-text">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${placedOrderCountFrom_3}&thruDate=${placedOrderCountThru_3}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_3}
              </h1>
            </Link>
          </h1>
        ),
      fifteenToThirtyDays:
        placedCount_4 === 0 ? (
          "0"
        ) : (
          <h1 className="text-link-text">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${placedOrderCountFrom_4}&thruDate=${placedOrderCountThru_4}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_4}
              </h1>
            </Link>
          </h1>
        ),
      thirtyOneToSixtyDays:
        placedCount_5 === 0 ? (
          "0"
        ) : (
          <h1 className="text-link-text">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${placedOrderCountFrom_5}&thruDate=${placedOrderCountThru_5}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_5}
              </h1>
            </Link>
          </h1>
        ),
      sixtyOneToNinetyDays:
        placedCount_6 === 0 ? (
          <h1 className="">0</h1>
        ) : (
          <h1 className="text-link-text ">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `fromDate=${placedOrderCountFrom_6}&thruDate=${placedOrderCountThru_6}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_6}
              </h1>
            </Link>
          </h1>
        ),
      ninetyOnePlusDays:
        placedCount_7 === 0 ? (
          "0"
        ) : (
          <h1 className="text-link-text ">
            <Link
              to={{
                pathname: `/order/orderListing`,
                search: `thruDate=${placedOrderCountThru_7}&statusId=OrderPlaced OR OrderProcessing`,
              }}
            >
              <h1 className="text-link-text hover:text-link-text underline">
                {" "}
                {placedCount_7}
              </h1>
            </Link>
          </h1>
        ),
    },

    {
      status: "approved",
      lessThanOneDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count1 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${todayDateData}&thruDate=${todayDateData}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count1}
            </h1>
          </Link>
        ),
      oneToSevenDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count2 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${approveOrderCountFrom_2}&thruDate=${approveOrderCountThru_2}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count2}
            </h1>
          </Link>
        ),
      eightToFourteenDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count3 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${approveOrderCountFrom_3}&thruDate=${approveOrderCountThru_3}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {" "}
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count3}
            </h1>
          </Link>
        ),
      fifteenToThirtyDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count4 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${approveOrderCountFrom_4}&thruDate=${approveOrderCountThru_4}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count4}
            </h1>
          </Link>
        ),
      thirtyOneToSixtyDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count5 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${approveOrderCountFrom_5}&thruDate=${approveOrderCountThru_5}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count5}
            </h1>
          </Link>
        ),
      sixtyOneToNinetyDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count6 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${approveOrderCountFrom_6}&thruDate=${approveOrderCountThru_6}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count6}
            </h1>
          </Link>
        ),
      ninetyOnePlusDays:
        orderAgingTableList.orderApprovedList &&
        orderAgingTableList.orderApprovedList.length > 0 &&
        orderAgingTableList.orderApprovedList[0].count7 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `thruDate=${approveOrderCountThru_7}&statusId=OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderApprovedList &&
                orderAgingTableList.orderApprovedList.length > 0 &&
                orderAgingTableList.orderApprovedList[0].count7}
            </h1>
          </Link>
        ),
    },
    {
      status: "held",
      lessThanOneDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count1 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${todayDateData}&thruDate=${todayDateData}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count1}
            </h1>
          </Link>
        ),
      oneToSevenDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count2 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${heldOrderCountFrom_2}&thruDate=${heldOrderCountThru_2}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count2}
            </h1>
          </Link>
        ),
      eightToFourteenDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count3 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${heldOrderCountFrom_3}&thruDate=${heldOrderCountThru_3}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {" "}
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count3}
            </h1>
          </Link>
        ),
      fifteenToThirtyDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count4 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${heldOrderCountFrom_4}&thruDate=${heldOrderCountThru_4}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {" "}
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count4}
            </h1>
          </Link>
        ),
      thirtyOneToSixtyDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count5 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${heldOrderCountFrom_5}&thruDate=${heldOrderCountThru_5}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {" "}
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count5}
            </h1>
          </Link>
        ),
      sixtyOneToNinetyDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count6 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${heldOrderCountFrom_6}&thruDate=${heldOrderCountThru_6}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {" "}
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count6}
            </h1>
          </Link>
        ),
      ninetyOnePlusDays:
        orderAgingTableList.orderHeldList &&
        orderAgingTableList.orderHeldList.length > 0 &&
        orderAgingTableList.orderHeldList[0].count7 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `thruDate=${heldOrderCountThru_7}&statusId=OrderHold`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.orderHeldList &&
                orderAgingTableList.orderHeldList.length > 0 &&
                orderAgingTableList.orderHeldList[0].count7}
            </h1>
          </Link>
        ),
    },
    {
      status: "preorder",
      lessThanOneDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count1 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${todayDateData}&thruDate=${todayDateData}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count1}
            </h1>
          </Link>
        ),
      oneToSevenDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count2 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${preOrderCountFrom_2}&thruDate=${preOrderCountThru_2}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count2}
            </h1>
          </Link>
        ),
      eightToFourteenDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count3 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${preOrderCountFrom_3}&thruDate=${preOrderCountThru_3}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count3}
            </h1>
          </Link>
        ),
      fifteenToThirtyDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count4 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${preOrderCountFrom_4}&thruDate=${preOrderCountThru_4}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count4}
            </h1>
          </Link>
        ),
      thirtyOneToSixtyDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count5 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${preOrderCountFrom_5}&thruDate=${preOrderCountThru_5}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count5}
            </h1>
          </Link>
        ),
      sixtyOneToNinetyDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count6 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${preOrderCountFrom_6}&thruDate=${preOrderCountThru_6}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count6}
            </h1>
          </Link>
        ),
      ninetyOnePlusDays:
        orderAgingTableList.preordersList &&
        orderAgingTableList.preordersList.length > 0 &&
        orderAgingTableList.preordersList[0].count7 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `thruDate=${preOrderCountThru_7}&queryString=!(statusId:OrderCompleted OR OrderCancelled)&facilityId=Preorder`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.preordersList &&
                orderAgingTableList.preordersList.length > 0 &&
                orderAgingTableList.preordersList[0].count7}
            </h1>
          </Link>
        ),
    },
    {
      status: "exchange",
      lessThanOneDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count1 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${todayDateData}&thruDate=${todayDateData}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count1}
            </h1>
          </Link>
        ),
      oneToSevenDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count2 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${exchangeOrderCountFrom_2}&thruDate=${exchangeOrderCountThru_2}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count2}
            </h1>
          </Link>
        ),
      eightToFourteenDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count3 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${exchangeOrderCountFrom_3}&thruDate=${exchangeOrderCountThru_3}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count3}
            </h1>
          </Link>
        ),
      fifteenToThirtyDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count4 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${exchangeOrderCountFrom_4}&thruDate=${exchangeOrderCountThru_4}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count4}
            </h1>
          </Link>
        ),
      thirtyOneToSixtyDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count5 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${exchangeOrderCountFrom_5}&thruDate=${exchangeOrderCountThru_5}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count5}
            </h1>
          </Link>
        ),
      sixtyOneToNinetyDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count6 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `fromDate=${exchangeOrderCountFrom_6}&thruDate=${exchangeOrderCountThru_6}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count6}
            </h1>
          </Link>
        ),
      ninetyOnePlusDays:
        orderAgingTableList.exchangeOrdersList &&
        orderAgingTableList.exchangeOrdersList.length > 0 &&
        orderAgingTableList.exchangeOrdersList[0].count7 === 0 ? (
          "0"
        ) : (
          <Link
            to={{
              pathname: `/order/orderListing`,
              search: `thruDate=${exchangeOrderCountThru_7}&statusId=OrderPlaced OR OrderHold OR OrderProcessing OR OrderApproved OR OrderSent`,
            }}
          >
            <h1 className="text-link-text hover:text-link-text underline">
              {orderAgingTableList.exchangeOrdersList &&
                orderAgingTableList.exchangeOrdersList.length > 0 &&
                orderAgingTableList.exchangeOrdersList[0].count7}
            </h1>
          </Link>
        ),
    },
  ];

  let filterDataList = dataSourceOrderAgingTable.filter((item) => {
    if (
      item.status === "placed" &&
      orderAgingTableList?.orderPlacedList?.length > 0
    ) {
      return true;
    } else if (
      item.status === "approved" &&
      orderAgingTableList?.orderApprovedList?.length > 0
    ) {
      return true;
    } else if (
      item.status === "held" &&
      orderAgingTableList?.orderHeldList?.length > 0
    ) {
      return true;
    } else if (
      item.status === "preorder" &&
      orderAgingTableList?.orderPreorderList?.length > 0
    ) {
      return true;
    } else if (
      item.status === "exchange" &&
      orderAgingTableList?.orderExchangeList?.length > 0
    ) {
      return true;
    }
    return false;
  });
  return (
    <>
      <div className="mb-5">
        <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">Timeframe</h1>
        <div className="flex flex-wrap items-center gap-3">
          <div
            className="flex flex-row items-center justify-between border bg-white mb-2 border-gray-300 rounded-md py-2 px-3 cursor-pointer w-60"
            onClick={() => setDateRangePickerVisible((prev) => !prev)}
          >
            <h1 className="text-gray-500 text-center ">{selectedDateRange}</h1>
            <IoMdArrowDropdown size={20} />
          </div>
          <div className="flex flex-wrap gap-2">
            {FILTER_BUTTONS?.map((btn: any, index: any) => (
              <div key={index} className="inActiveButton">
                <Button
                  defaultColor=""
                  classNames={`${
                    activeFilter === btn
                      ? "activeButton px-3 py-2 rounded-md font-medium"
                      : "bg-white border border-gray-300 px-3 py-2 rounded-md font-medium inActiveButton mb-2"
                  }`}
                  buttonText={btn}
                  onClick={() => handleDateRangeButtonClick(btn)}
                />
              </div>
            ))}
          </div>
        </div>
        {isDateRangePickerVisible && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setDateRangePickerVisible(false);
            }}
          >
            <DateRangePicker
              rangeColors={["#093479"]}
              inputRanges={[]}
              className="z-10 absolute l-0 t-0 shadow-custom-1"
              onChange={async (item) => {
                setIsLoading(true);
                const startDateItem = item.selection.startDate?.toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );
                let fullDate: any = startDateItem?.split(",")[0];
                fullDate = fullDate?.split("/");
                const endDateItem = item.selection.endDate?.toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );

                let fullEndDate: any = endDateItem?.split(",")[0];
                fullEndDate = fullEndDate?.split("/");

                const fromDate = `${fullDate[2]}-${fullDate[0]}-${fullDate[1]}T00:00:00`;
                const thruDate = `${fullEndDate[2]}-${fullEndDate[0]}-${fullEndDate[1]}T23:59:59T23:59:59`;
                const dashboardFromDate = `${fullDate[2]}-${fullDate[0]}-${fullDate[1]}`;
                const dashboardThruDate = `${fullEndDate[2]}-${fullEndDate[0]}-${fullEndDate[1]}`;
                await setDate([item.selection]);
                if (item.selection.endDate !== item.selection.startDate) {
                  setSelectedDateRange(
                    `${dashboardFromDate} - ${dashboardThruDate}`
                  );
                  setActiveFilter("");
                  setDateRangePickerVisible(false);
                  localStorage.removeItem("activeFilter");
                  await dispatch(
                    getDashboardReports({
                      setDashboardDetails,
                      monthStartDate: dashboardFromDate,
                      monthEndDate: dashboardThruDate,
                    })
                  );
                }
                localStorage.setItem("dashboardFromDate", dashboardFromDate);
                localStorage.setItem("dashboardThruDate", dashboardThruDate);
                setIsLoading(false);
                setDashboardFromDate(dashboardFromDate);
                setDashbordThruDate(dashboardThruDate);
              }}
              retainEndDateOnFirstSelection={true}
              months={1}
              ranges={date}
              staticRanges={[]}
              direction="horizontal"
            />
          </OutsideClickHandler>
        )}
      </div>
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        <div>
          <div className="grid grid-rows-5 md:grid-rows-3 lg:grid-rows-1 grid-flow-col gap-5 xl:gap-7 mb-8">
            {dashboardDetails.totalOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate
                      ? dashboardFromDate
                      : startingDate
                      ? startingDate
                      : localStorage.getItem("dashboardFromDate")
                  }&thruDate=${
                    dashboardThruDate
                      ? dashboardThruDate
                      : todayDate
                      ? todayDate
                      : localStorage.getItem("dashboardThruDate")
                  }`,
                }}
                onClick={() => {
                  localStorage.setItem("currentSubScreen", "orderListing");
                  localStorage.setItem("fromDashboardScreen", "true");
                }}
              >
                <GridTile
                  value={
                    dashboardDetails.totalOrders
                      ? dashboardDetails.totalOrders
                      : "0"
                  }
                  iconComponent={
                    <BsCart3
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Total Orders"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalOrders
                    ? dashboardDetails.totalOrders
                    : "0"
                }
                iconComponent={
                  <BsCart3
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Total Orders"
              />
            )}
            {dashboardDetails.totalShippedOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderCompleted`,
                }}
              >
                <GridTile
                  value={
                    dashboardDetails.totalShippedOrders
                      ? dashboardDetails.totalShippedOrders
                      : "0"
                  }
                  iconComponent={
                    <MdOutlineLocalShipping
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Orders Shipped"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalShippedOrders
                    ? dashboardDetails.totalShippedOrders
                    : "0"
                }
                iconComponent={
                  <MdOutlineLocalShipping
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Orders Shipped"
              />
            )}

            {dashboardDetails.totalHoldOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderHold`,
                }}
              >
                <GridTile
                  iconComponent={
                    <AiOutlinePause
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  value={
                    dashboardDetails.totalHoldOrders
                      ? dashboardDetails.totalHoldOrders
                      : "0"
                  }
                  title="Orders On-Hold"
                />
              </Link>
            ) : (
              <GridTile
                iconComponent={
                  <AiOutlinePause
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                value={
                  dashboardDetails.totalHoldOrders
                    ? dashboardDetails.totalHoldOrders
                    : "0"
                }
                title="Orders On-Hold"
              />
            )}
            {dashboardDetails.totalReturnOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&isReturnedOrders=true`,
                }}
              >
                <GridTile
                  value={
                    dashboardDetails.totalReturnOrders
                      ? dashboardDetails.totalReturnOrders
                      : "0"
                  }
                  iconComponent={
                    <TbTruckReturn
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />
                  }
                  title="Orders Returned"
                />
              </Link>
            ) : (
              <GridTile
                value={
                  dashboardDetails.totalReturnOrders
                    ? dashboardDetails.totalReturnOrders
                    : "0"
                }
                iconComponent={
                  <TbTruckReturn
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                title="Orders Returned"
              />
            )}

            {dashboardDetails.totalCancelOrders ? (
              <Link
                to={{
                  pathname: "/order/orderListing",
                  search: `fromDate=${
                    dashboardFromDate ? dashboardFromDate : startingDate
                  }&thruDate=${
                    dashboardThruDate ? dashboardThruDate : todayDate
                  }&statusId=OrderCancelled`,
                }}
              >
                <GridTile
                  iconComponent={
                    <BsCartX
                      size={60}
                      color="#093479"
                      className="rounded-xl shadow-lg mb-5 p-4"
                    />

                    // <CancelledOrders className="rounded-xl shadow-lg mb-5" />
                  }
                  value={
                    dashboardDetails.totalCancelOrders
                      ? dashboardDetails.totalCancelOrders
                      : "0"
                  }
                  title="Orders Cancelled"
                />
              </Link>
            ) : (
              <GridTile
                iconComponent={
                  <BsCartX
                    size={60}
                    color="#093479"
                    className="rounded-xl shadow-lg mb-5 p-4"
                  />
                }
                value={
                  dashboardDetails.totalCancelOrders
                    ? dashboardDetails.totalCancelOrders
                    : "0"
                }
                title="Orders Cancelled"
              />
            )}
          </div>
        </div>
      </Loader>
      <div>
        <div className="bg-white rounded-lg p-5 shadow-sm mt-6 mb-5">
          <div className="ml-3 mb-2 text-lg font-bold">
            <h1 className="">Order Aging Report</h1>
          </div>
          <Loader
            isLoading={isFirstTimeLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "white",
              }),
            }}
          >
            <Table
              dataSource={filterDataList}
              columns={columnsOrderAgingTable}
              rowKey="lessThanOneDays"
              isPaginated={false}
            />
            <div className="mt-6 md:flex md:flex-row justify-center">
              <div className="mb-5 md:mb-0">
                <div className="flex flex-row">
                  <h1 className="text-xs font-bold w-20 mr-2 md:mr-0">
                    Placed
                  </h1>
                  <span
                    className="text-xs font-medium"
                    style={{ lineHeight: "1.3em" }}
                  >
                    Orders that are waiting to be approved or cancelled
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <h1 className="text-xs font-bold w-20 mr-2 md:mr-0">
                    Approved
                  </h1>
                  <span
                    className="text-xs font-medium"
                    style={{ lineHeight: "1.3em" }}
                  >
                    Orders that have passed fraud screening and are ready to be
                    shipped
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <h1 className="text-xs font-bold w-20 mr-2 md:mr-0">Held</h1>
                  <span
                    className="text-xs font-medium"
                    style={{ lineHeight: "1.3em" }}
                  >
                    Orders in fraud review; held orders should be approved or
                    cancelled
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <h1 className="text-xs font-bold w-20 mr-2 md:mr-0">
                    Preorder
                  </h1>
                  <span
                    className="text-xs font-medium"
                    style={{ lineHeight: "1.3em" }}
                  >
                    Orders with at least one item that is a preorder that is not
                    ready for shipment
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <h1 className="text-xs font-bold w-20 mr-2 md:mr-0">
                    Exchanges
                  </h1>
                  <div className="flex flex-col">
                    <span
                      className="text-xs font-medium"
                      style={{ lineHeight: "1.3em" }}
                    >
                      Orders that are placed when a customer requests an
                      exchange for a returned item.
                    </span>
                    <span
                      className="text-xs font-medium"
                      style={{ lineHeight: "1.3em" }}
                    >
                      These orders are automatically closed if the customer
                      never returns the item.
                    </span>
                  </div>
                </div>
              </div>
              <div className="ml-10">
                <div className="flex flex-row mt-1.5">
                  <div
                    style={{ backgroundColor: "#58BB55" }}
                    className="h-3.5 w-3.5 rounded-full"
                  ></div>
                  <span className="text-xs ml-2 font-medium">
                    All orders{" "}
                    <span className="text-xs ml-1 font-bold">
                      under 1 day old
                    </span>
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <div
                    style={{ backgroundColor: "#FA6400" }}
                    className="h-3.5 w-3.5 rounded-full"
                  ></div>
                  <span className="text-xs font-medium ml-2">
                    Some orders{" "}
                    <span className="text-xs font-bold">up to 7 days old </span>
                  </span>
                </div>
                <div className="flex flex-row mt-1.5">
                  <div
                    style={{ backgroundColor: "#E02020" }}
                    className="h-3.5 w-3.5 rounded-full"
                  ></div>
                  <span className="text-xs font-medium ml-2">
                    Some orders{" "}
                    <span className="text-xs font-bold">over 7 days old </span>
                  </span>
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    </>
  );
}

export default memo(DasboardMain);
