/* eslint-disable */
import React, { useEffect, useState, ReactElement } from "react";
import { Form, FormInstance, notification, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  userSelector,
  clearState,
  getShipCarriers,
  getShipMethods,
  getGeoCountries,
  getGeoStates,
  createIncomingShipment,
  getPartyDetailRoleType,
  getIncomingShipmentAddress,
} from "../redux/authSlice";

import "../antd.css";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Input from "../components/Input";
import DatePicker from "../components/DatePicker";
import UploadFile from "./UploadFile";
import { DownloadOutlined } from "@ant-design/icons";
import Dropdown from "../components/Dropdown";

function InventoryShip({
  facilitiesList,
  selectedFacility,
  setSelectedFacility,
}: {
  facilitiesList: any;
  selectedFacility: any;
  setSelectedFacility: any;
}): ReactElement {
  const formRef = React.createRef<FormInstance>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [csvFile, setCsvFile] = useState<any>(null);
  const [shipDate, setShipDate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shipMethod, setShipMethod] = useState("");
  const [partyIdListing, setPartyIdListing] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>("1");
  const [currentValue, setCurrentValue] = useState();
  const [, setPageCount] = useState<any>("");
  const [searchTerm, setSearchTerm] = useState("");
  const [customerShippingInfo, setCustomerShippingInfo] = useState<any>({});
  const [isCustomerInformationVisible, setCustomerInformationVisible] =
    useState<any>(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState<any>(false);
  const [vendorPartyId, setVendorPartyId] = useState("");
  function onChange(date: any, dateString: any) {
    const newDate = new Date();
    const fmtdTime = newDate.getHours() + ":" + newDate.getMinutes();
    const finalDate = dateString + " " + fmtdTime;
    setShipDate(finalDate);
  }
  const {
    isSuccess,
    shipCarriers,
    shipMethods,
    countriesList,
    statesList,
    hasShipmentId,
  } = useSelector(userSelector);
  useEffect(() => {
    localStorage.setItem("currentPath", "/inventory/createIncomingShipment");
    const isAuth = localStorage.getItem("loggedInToken");
    if (!isAuth) {
      history.push("/");
    } else {
      dispatch(getShipCarriers());
      dispatch(getGeoCountries());
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (hasShipmentId) {
      dispatch(clearState());
      notification["success"]({
        message: "Success",
        description: "Successfully created incoming shipment!",
      });
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess, dispatch, hasShipmentId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchPartyId: any = async () => {
      await dispatch(
        getPartyDetailRoleType({
          organizationPartyId: localStorage.getItem("organizationPartyId"),
          setPartyIdListing,
          setPageCount,
          setMaxPageIndex,
          pageIndex: 0,
        })
      );
    };
    fetchPartyId();
  }, [dispatch, history]);
  const selectShipCarrier = (val: any) => {
    formRef.current?.setFieldsValue({
      shipCarrier: val,
    });
    dispatch(getShipMethods(val));
  };
  const selectShipMethod = (val: any) => {
    formRef.current?.setFieldsValue({
      shipMethod: val,
    });
    setShipMethod(val);
  };
  const selectCountry = (val: any) => {
    formRef.current?.setFieldsValue({
      country: val,
    });
    dispatch(getGeoStates(val));
    formRef?.current?.resetFields(["state"]);
  };
  useEffect(() => {
    if (Object.keys(customerShippingInfo).length) {
      formRef.current?.setFieldsValue({ ...customerShippingInfo });
    }
  }, [customerShippingInfo]); // eslint-disable-line
  const selectShipToCustomer = async (val: any) => {
    formRef.current?.setFieldsValue({
      shipToCustomer: val,
    });
    setCurrentValue(val);
    if (val !== "new_customer") {
      setIsExistingCustomer(true);
      await dispatch(
        getIncomingShipmentAddress({
          setCustomerShippingInfo,
          PartyId: val,
        })
      );
      setVendorPartyId(val);
    } else {
      formRef.current?.resetFields([
        "name",
        "email",
        "contact",
        "address1",
        "address2",
        "city",
        "postalCode",
        "country",
        "state",
        "orderNum",
        "shippingInstructions",
      ]);
    }
  };
  const nextSetOfProds = async (pageIndex: any) => {
    await dispatch(
      getPartyDetailRoleType({
        organizationPartyId: localStorage.getItem("organizationPartyId"),
        setPartyIdListing,
        setPageCount,
        setMaxPageIndex,
        pageIndex,
        searchTerm,
      })
    );
  };
  const loadProdsOnBtnClick = (type: string) => {
    if (type === "left") {
      if (
        maxPageIndex * 1 === 1 ||
        !currIndex ||
        currIndex * 1 === 0 ||
        (Math.abs(currIndex * 1 - maxPageIndex * 1) > 1 &&
          currIndex * 1 > maxPageIndex * 1) ||
        currIndex * 1 === 1
      ) {
        return;
      }
      const passedIndex = currIndex * 1 - 2;
      setCurrIndex((p: any) => p * 1 - 1);
      nextSetOfProds(passedIndex);
    } else {
      if (!currIndex || currIndex * 1 >= maxPageIndex) {
        return;
      }
      const passedIndex = currIndex;
      setCurrIndex((p: any) => p * 1 + 1);
      nextSetOfProds(passedIndex);
    }
  };
  const searchBoxOnChange = async (input: any) => {
    setSearchTerm(input);
    await dispatch(
      getPartyDetailRoleType({
        organizationPartyId: localStorage.getItem("organizationPartyId"),
        setPartyIdListing,
        setPageCount,
        setMaxPageIndex,
        pageIndex: 0,
        searchTerm: input,
      })
    );
  };
  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        // spinner={
        //   <Image
        //     className="animate-spin"
        //     width={25}
        //     src={SpinnerSvg}
        //     preview={false}
        //   />
        // }
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          {/* <p className="text-black font-light mb-4">
            We still need some inventory from you before we can go live.
          </p> */}
          <p className="text-black font-light mb-8">
            Please be sure to submit this form when you send inventory to us so
            that our warehouse team doesn't get mad at us. Seriously, they're
            the best, let's make it easier on them.
          </p>
        </div>
        <div>
          <Form
            name="incoming_shipment"
            ref={formRef}
            initialValues={{ address2: "" }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              // if (!csvFile || csvFile.status === "removed") {
              //   notification["error"]({
              //     message: "Error",
              //     description: "Please select a CSV file!",
              //   });
              //   return;
              // }
              const accountId = localStorage.getItem("accountId");
              if (!accountId) {
                notification["error"]({
                  message: "Error",
                  description: "Accound Id could not be found!",
                });
                return;
              }
              let formData = new FormData();
              // formData.append("contentFile", csvFile);
              formData.append("accountId", accountId);
              formData.append("carrierPartyId", values.shipCarrier);
              formData.append("shipmentTypeEnumId", "ShpTpIncoming");
              formData.append("shipmentMethodEnumId", values.shipMethod);
              formData.append("poNumber", values.orderNum);
              formData.append(
                "otherShippingMethod",
                values?.otherShippingMethod ?? ""
              );
              formData.append("estimatedArrivalDate", shipDate);
              formData.append("senderName", values.name);
              if (values.email) {
                formData.append("senderEmail", values.email);
              }
              formData.append("senderContactNumber", values.contact);
              formData.append("address1", values.address1);
              formData.append("address2", values.address2);
              formData.append("city", values.city);
              formData.append(
                "shippingInstructions",
                values.shippingInstructions ? values.shippingInstructions : ""
              );
              formData.append("stateCode", values.state);
              formData.append(
                "destinationFacilityId",
                values.destinationFacilityId
              );
              formData.append("countryGeoId", values.country);
              formData.append("postalCode", values.postalCode);
              formData.append("saveCustomerInfo", isCustomerInformationVisible);
              values?.shipToCustomer !== "new_customer" &&
                formData.append("vendorPartyId", vendorPartyId);
              await dispatch(
                createIncomingShipment({ data: formData, history })
              );
              setIsLoading(false);
            }}
          >
            <div className="w-full">
              <Form.Item
                name="arrivalDate"
                rules={[
                  {
                    required: true,
                    message: "Please select date!",
                  },
                ]}
              >
                <DatePicker
                  onChange={onChange}
                  title="Estimated Arrival Date"
                />
              </Form.Item>
              <Form.Item
                className="w-full sm:w-1/2"
                name="destinationFacilityId"
                initialValue={
                  selectedFacility === "None" ? "" : selectedFacility
                }
                rules={[
                  {
                    required: true,
                    message: "Select an option!",
                  },
                ]}
              >
                <Dropdown
                  title="Ship to Facility"
                  placeholder="Select facility"
                  data={facilitiesList}
                  onChange={(val: any) => {
                    setSelectedFacility(val);
                  }}
                />
              </Form.Item>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="shipCarrier"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    placeholder="Select one"
                    data={shipCarriers}
                    onChange={selectShipCarrier}
                    title="Ship Carrier"
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="shipMethod"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    placeholder="Select one"
                    data={shipMethods}
                    onChange={selectShipMethod}
                    title="Ship Method"
                  />
                </Form.Item>
              </div>
              <Form.Item name="otherShippingMethod">
                <Input
                  title="* Field will be enabled when Ship Method is selected to Others"
                  placeholder="Other Shipping Method"
                  showDot={true}
                  isDisabled={shipMethod !== "ShMthOthers"}
                />
              </Form.Item>
              <Form.Item
                name="shipToCustomer"
                rules={[{ required: true, message: "Name is required!" }]}
              >
                <Dropdown
                  isSearchBoxVisible={true}
                  searchBoxOnChange={searchBoxOnChange}
                  placeholder="Select one"
                  data={partyIdListing}
                  onChange={selectShipToCustomer}
                  title="Ship From"
                  isPaginated={true}
                  loadProds={loadProdsOnBtnClick}
                  currIndex={currIndex}
                  maxPageIndex={maxPageIndex}
                  value={currentValue}
                />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Name is required!" }]}
              >
                <Input title="" placeholder="Sender Name" showDot={true} />
              </Form.Item>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Email should be a valid one!",
                    },
                  ]}
                >
                  <Input title="" placeholder="Sender Email" showDot={true} />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="contact"
                  rules={[
                    { required: true, message: "Contact number is required!" },
                    {
                      pattern:
                        /^(\+?\d{1,3}[- .]?( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4,7}([xX]{1}(\d{1,4}))?$/,
                      message: "Please enter a valid Contact number",
                    },
                  ]}
                >
                  <Input
                    title=""
                    placeholder="Sender Contact Number"
                    showDot={true}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="address1"
                rules={[{ required: true, message: "Address 1 is required!" }]}
              >
                <Input title="Address" placeholder="Address 1" showDot={true} />
              </Form.Item>
              <Form.Item
                name="address2"
                //rules={[{ required: true, message: 'Address 2 is required!' }]}
              >
                <Input title="" placeholder="Address 2" showDot={false} />
              </Form.Item>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="city"
                  rules={[{ required: true, message: "City is required!" }]}
                >
                  <Input title="" placeholder="City" showDot={true} />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="postalCode"
                  rules={[
                    { required: true, message: "Postal Code is required!" },
                  ]}
                >
                  <Input title="" placeholder="Postal Code" showDot={true} />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="country"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    placeholder="Country"
                    data={countriesList}
                    onChange={selectCountry}
                    searchable={true}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="state"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    placeholder="State"
                    data={[...statesList, { key: "_NA_", value: "_NA_" }]}
                    searchable={true}
                  />
                </Form.Item>
              </div>
              <div className="flex flex-row-reverse mb-3">
                <div>
                  <Switch
                    checked={isCustomerInformationVisible}
                    onChange={(checked) =>
                      setCustomerInformationVisible(checked)
                    }
                  />
                </div>
                <div className="mr-3 font-semibold text-gray-500">
                  Save Customer Information
                </div>
              </div>
              <Form.Item
                name="orderNum"
                rules={[
                  {
                    required: true,
                    message: "Order Number is required!",
                  },
                ]}
              >
                <Input
                  title="PO (Order) Number"
                  placeholder=""
                  showDot={true}
                />
              </Form.Item>
              <Form.Item name="shippingInstructions">
                <Input
                  multiline={true}
                  title="Shipping Instructions"
                  placeholder=""
                  showDot={false}
                />
              </Form.Item>
              {/* <Form.Item>
                <UploadFile setCsvFile={setCsvFile} isCloudIconVisible={true} />
              </Form.Item>
              <div
                className="flex items-center mb-3 cursor-pointer w-max"
                onClick={() => {
                  window.location.href =
                    process.env.REACT_APP_API_BASE_URL +
                    "/fp-static/downloadIncomingShipmentTemplate";
                }}
              >
                <DownloadOutlined style={{ fontSize: "18px" }} />
                <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                  DOWNLOAD CSV TEMPLATE
                </p>
              </div> */}
            </div>
            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button widthFull={true} buttonText="Create" />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default InventoryShip;
